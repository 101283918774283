/**
 * 游戏记录工具类
 */
import { EnumerationValueEnum, SettlementEnum, DetailStatusEnum, timePeriodEnum } from '@/constants/entertainment-area'
import { UserUpsAndDownsColorEnum } from '@/constants/user'
import dayjs from 'dayjs'

/**
 * 表单默认值
 */
export const fromDefault = {
  orderNo: '',
  startTime: dayjs().startOf('day').valueOf(),
  endTime: dayjs().endOf('day').valueOf(),
  groupType: '',
  gameTypeId: undefined,
  groupId: 0,
  pageNum: 1,
  pageSize: 10,
  groupTypeId: undefined,
  productCd: 0,
}

/**
 * 盈亏颜色判断方法
 * @param {number} profitLoss 表格当前对象值
 * @param {number} colors 涨跌色
 * @return {String}
 */
export const profitLossFn = (profitLoss, colors) => {
  let greenUpRedDown = EnumerationValueEnum.TabelGreen
  let redUpGreenDown = EnumerationValueEnum.TabelRed
  if (profitLoss) {
    if (colors === UserUpsAndDownsColorEnum.greenUpRedDown) {
      return profitLoss > 0 ? greenUpRedDown : redUpGreenDown
    }
    return profitLoss > 0 ? redUpGreenDown : greenUpRedDown
  }
  return ''
}

/**
 * 颜色筛选方法
 */
export function colorFn(num) {
  const classMap = {
    [SettlementEnum.Ongoing]: EnumerationValueEnum.TabelAuxiliary,
    [SettlementEnum.NotSettled]: EnumerationValueEnum.TabelAuxiliary,
    [SettlementEnum.Billing]: EnumerationValueEnum.TabelAuxiliary,
    [SettlementEnum.win]: EnumerationValueEnum.TabelGreen,
    [SettlementEnum.lose]: EnumerationValueEnum.TabelRed,
    [SettlementEnum.ExceptionallyFrozen]: EnumerationValueEnum.TabelRed,
    [SettlementEnum.UnknownStatus]: EnumerationValueEnum.TabelRed,
    [DetailStatusEnum.fail]: EnumerationValueEnum.TabelRed,
    [DetailStatusEnum.success]: EnumerationValueEnum.TabelGreen,
  }
  return classMap[num] || ''
}

/**
 * 支付余额颜色
 */
export function profitLossNegative(num) {
  return num > 0 ? EnumerationValueEnum.TabelGreen : EnumerationValueEnum.TabelRed
}
